import { APP_ID, Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-first-app';

  ngOnInit(){
  setTimeout(()=>{
        localStorage.clear();
  },3600000)                    // expire localstorage after 1 hour
 }

  api() {
    console.log("api route")
  }
}
