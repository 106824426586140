import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../global/global-constants';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  password = "";
  email = "";
  s_email = "";
  s_username = "";
  s_firstname = "";
  s_lastname = "";
  s_password = "";
  s_accept = false;
  checked = false;
  sign = "false";
  eye = false;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService) { 
    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/home');
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  s_emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  s_usernameFormControl = new FormControl('', [
    Validators.required
  ]);
  s_firstnameFormControl = new FormControl('', [
    Validators.required
  ]);
  s_lastnameFormControl = new FormControl('', [
    Validators.required
  ]);
  s_passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  s_acceptFormControl = new FormControl('', [
    Validators.required
  ]);

  loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required
      ])
  })

  registerForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    name: new FormControl('', [
      Validators.required
    ]),
    s_firstname: new FormControl('', [
      Validators.required
    ]),
    s_lastname: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required
    ]),
    s_accept: new FormControl('', [
      Validators.required
    ])
})

  register() {
    if(!this.s_emailFormControl.hasError('required') && !this.s_emailFormControl.hasError('email') && !this.s_usernameFormControl.hasError('required') && !this.s_firstnameFormControl.hasError('required') && !this.s_lastnameFormControl.hasError('required') && !this.s_passwordFormControl.hasError('required') && !this.s_acceptFormControl.hasError('required')) {
      // console.log(JSON.stringify(this.registerForm.value, null, 4))
      // const headers = { 'Access-Control-Allow-Origin': '*' };
      if(this.s_accept) {
        const body = this.registerForm.value;
        this.http.post<any>(GlobalConstants.apiURL + '/register', body).subscribe(data => {
          if(data.status == 'success') {
            this.toastr.success("Welcome to My Homepage!", "Sign Up Success!", { timeOut: 3000, });
            localStorage.setItem("name", data.data['name']);
            localStorage.setItem("email", data.data['email']);
            localStorage.setItem("token", data.data['token']);
            localStorage.setItem("user_profile_id", data.data['user_profile_id']);
            this.router.navigateByUrl('/home');
          } else {
            this.toastr.error(JSON.stringify(data.error.email[0], null, 4), "Sign Up Failed", { timeOut: 3000, });
          }
        });
      } else {
        this.toastr.warning('Terms and conditions field required!', "Sign Up Failed", { timeOut: 3000, });
      }
    }
  }

  login() {
    if(!this.passwordFormControl.hasError('required') && !this.emailFormControl.hasError('required') && !this.emailFormControl.hasError('email')) {
      const body = this.loginForm.value;
      this.http.post<any>(GlobalConstants.apiURL + '/login', body).subscribe(data => {
        if(data.status == 'success') {
          this.toastr.success("Welcome to My Homepage!", "Sign In Success!", { timeOut: 3000, });
          localStorage.setItem("name", data.data['name']);
          localStorage.setItem("email", data.data['email']);
          localStorage.setItem("token", data.data['token']);
          localStorage.setItem("user_profile_id", data.data['user_profile_id']);
          this.router.navigateByUrl('/home');
        } else {
          this.toastr.error("Credential is incorrect!", "Sign In Failed!", { timeOut: 3000, });
        }
      });
    }
  }

  ngOnInit() {
  }

}
