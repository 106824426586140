import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { GlobalConstants } from '../global/global-constants';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':leave', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  show = false;
  notifications: any;
  notify_count = 0;
  interval: any;

  constructor(private router: Router, private http: HttpClient) { 
    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('/');
    }
  }

  addpost() {
    this.show = !this.show
    this.router.navigateByUrl('addpost');
  }

  signup() {
    this.router.navigateByUrl('/');
  }

  ngOnInit() {
    var options = { 
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    };
    
    this.interval = setInterval(() => {
      this.http.get<any>(GlobalConstants.apiURL + '/getnotification', options).subscribe(data => {
        let stringJson = JSON.stringify(data['notifications']);
        this.notifications = JSON.parse(stringJson);
        this.notify_count = data['notifications_count']
        GlobalConstants.notification = data
      });
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
